import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Button, Modal, ModalBody, ModalHeader, FormInput } from 'shards-react';
import PasswordAndCommentModal, { useToggleModal } from '../../../components/common/PasswordAndCommentModal';
import BonusModal, { useToggleModal as useBonusModal } from './BonusModal';
import Tip from '../../../components/Tip';
import colors from '../../../utils/colors';
import moment from 'moment';
import ReactTable from 'react-table';
import API from '../../../data/API';
import RequirePermissions from '../../../components/RequirePermissions';
import { toTitleCase } from '../../../utils/string';

const tableColumns = [
	{
		id: 'date',
		Header: 'Date',
		accessor: row => moment(row.date).format('MM/DD/YYYY'),
		className: 'text-center d-flex align-items-center',
		maxWidth: 120,
	},

	{
		Header: 'Description',
		accessor: 'description',
		className: 'text-center d-flex align-items-center',
		// maxWidth: 120,
		Cell: row => (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
					width: '100%',
					margin: '6px 0',
				}}>
				<div>
					<span
						style={{
							fontSize: 16,
						}}>
						{row.original.description}
					</span>
					{row.original.label && (
						<span
							style={{
								fontSize: 12,
								marginLeft: 5,
								background: '#657c9d',
								padding: '2px 5px',
								borderRadius: 5,
								color: '#fff',
							}}>
							{row.original.label}
						</span>
					)}
				</div>
				{row.original.originalDescription && (
					<div
						style={{
							fontSize: 13,
							fontWeight: 300,
							color: '#c5c5c5',
						}}>
						{row.original.originalDescription}
					</div>
				)}
			</div>
		),
	},
	{
		Header: 'Note',
		accessor: 'note',
		className: 'text-center d-flex align-items-center',
		// maxWidth: 120,
		Cell: row => <div>{row.original.note}</div>,
	},
	{
		Header: 'Type',
		accessor: 'type',
		className: 'text-center d-flex align-items-center',
		maxWidth: 120,
	},
	{
		Header: 'Amount ($)',
		accessor: 'amount',
		className: 'text-center d-flex align-items-center',
		maxWidth: 120,
	},
];

const AccountDisplay = ({ account, onRefresh, businessProfileId }) => {
	const [open, toggle] = useToggleModal();
	const [bonusModalOpen, toggleBonusModal] = useBonusModal();
	const [statementList, setStatementList] = useState([]);

	const getStatementlist = async () => {
		const { statements } = await API.get(
			`/banking-admin/statements/business/list/${businessProfileId}/${account.type}`,
		);
		setStatementList(
			statements.map(({ cycleMonthString, statementId }) => ({
				cycleMonthString: moment(cycleMonthString).format('MMMM YYYY'),
				downloadLink: `/banking-admin/statements/business/download/${businessProfileId}/${statementId}`,
			})),
		);
	};

	const downloadStatement = async (cycleMonthString, downloadLink) => {
		const data = await API.download(downloadLink);

		const fileName = `${toTitleCase(account.type)} - ${moment(cycleMonthString).format('MMM YYYY')}.pdf`;

		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
	};

	const statementsTableColumns = [
		{
			id: 'cycleMonthString',
			Header: '',
			accessor: 'cycleMonthString',
			className: 'text-left d-flex align-items-left',
			width: '50%',
		},
		{
			Header: '',
			accessor: 'downloadLink',
			className: 'text-left d-flex align-items-left',
			width: '50%',
			Cell: row => (
				<div
					style={{ width: '100%', cursor: 'pointer' }}
					theme="light"
					onClick={() => {
						downloadStatement(row.original.cycleMonthString, row.original.downloadLink);
					}}>
					Download
				</div>
			),
		},
	];

	useEffect(() => {
		getStatementlist();
	}, [account]);

	const closeAccount = async (password, comment) => {
		await API.post(`/banking-admin/business/close-account/${businessProfileId}`, {
			type: account.type,
			password,
			comment,
		});
	};

	const onConfirmCloseAccount = async (password, comment) => {
		await closeAccount(password, comment);
		await onRefresh(account.type);
	};

	const onConfirmPayBonus = async (password, amountInCents, comment) => {
		await API.post(`/banking-admin/bonus/pay`, {
			businessProfileId,
			amountInCents,
			comment,
			password,
		});
		setTimeout(() => {
			window.location.reload();
		}, 3000);
	};

	return (
		<>
			<PasswordAndCommentModal
				actionLabel={`close ${account.type} account`}
				onConfirm={onConfirmCloseAccount}
				toggle={open}
			/>
			<BonusModal onConfirm={onConfirmPayBonus} toggle={bonusModalOpen} />
			<Row className="py-4 d-flex justify-content-between">
				{account ? (
					<Col
						xs="12"
						sm="12"
						md="12"
						lg="12"
						xl="12"
						// className="col d-flex align-items-center justify-content-between"
					>
						<Card small className="mb-3">
							<CardHeader className="border-bottom d-flex align-items-center justify-content-between">
								<h6 className="m-0" style={{ flex: 1 }}>
									{account && (account.type === 'checking' ? 'Checking' : 'Savings')}
								</h6>
								{account.type === 'checking' && (
									<RequirePermissions permissions="bonus.write">
										<Button theme="light" onClick={toggleBonusModal} className="mr-2">
											Pay Bonus
										</Button>
									</RequirePermissions>
								)}
								{account.status === 'close_pending' || account.status === 'closed' ? null : (
									<RequirePermissions permissions="business.write">
										<Button theme="light" onClick={toggle}>
											Close Account
										</Button>
									</RequirePermissions>
								)}
							</CardHeader>
							<CardBody>
								<Row className="px-3">
									<div style={styles.itemWrapper}>
										<div style={styles.itemLabel}>
											<Tip
												style={{
													fontSize: 12,
													fontWeight: 'normal',
													textTransform: 'capitalize',
												}}
												color={colors.black.value}
												prompt="Avaliable Balance" //320 for "HD"
												helpText="Avaliable Balance ......"
												place="top"
											/>
										</div>
										<div style={styles.itemValue}>{account.availableBalance}</div>
									</div>
									<div style={styles.itemWrapper}>
										<div style={styles.itemLabel}>
											<Tip
												style={{
													fontSize: 12,
													fontWeight: 'normal',
													textTransform: 'capitalize',
												}}
												color={colors.black.value}
												prompt=" Current Balance" //320 for "HD"
												helpText=" Current Balance ......"
												place="top"
											/>
										</div>
										<div style={styles.itemValue}>{account.currentBalance}</div>
									</div>
									<div style={styles.itemWrapper}>
										<div style={styles.itemLabel}>Status</div>
										<div style={styles.itemValue}>{account.status}</div>
									</div>
									<div style={styles.itemWrapper}>
										<div style={styles.itemLabel}>Last 4 of Account Number</div>
										<div style={styles.itemValue}>{account.last4OfAccountNumber}</div>
									</div>
									<div style={{ ...styles.itemWrapper, marginRight: 0 }}>
										<div style={styles.itemLabel}>Created</div>
										<div style={styles.itemValue}>
											{moment(account.created).format('MM/DD/YYYY')}
										</div>
									</div>
								</Row>
							</CardBody>
						</Card>
					</Col>
				) : null}
			</Row>
			<Row>
				<Col lg="12" md="12">
					<Card small className="mb-3">
						<CardHeader className="border-bottom">
							<h6 className="m-0">Activity</h6>
						</CardHeader>

						<CardBody className="p-0">
							<ReactTable
								getTdProps={(state, rowInfo) => ({
									style: {
										borderLeft: `none`,
										borderRight: `none`,
										...(rowInfo?.original?.type === 'Pending'
											? {
													color: '#657C9D',
													fontWeight: 400,
											  }
											: null),
									},
								})}
								className="-highlight"
								columns={tableColumns}
								data={account.accountActivity}
								resizable={false}
								usePagination={true}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col lg="12" md="12">
					<Card small className="mb-3">
						<CardHeader className="border-bottom">
							<h6 className="m-0">Statements</h6>
						</CardHeader>

						<CardBody className="p-0">
							<ReactTable
								getTheadProps={() => ({
									style: { display: 'none' },
								})}
								getTdProps={() => ({
									style: { justifyContent: 'flex-start' },
								})}
								className="-highlight"
								columns={statementsTableColumns}
								data={statementList}
								resizable={false}
								usePagination={false}
								showPaginationBottom={false}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};

const styles = {
	cardContainer: {
		// minWidth: 558,
		height: 200,
		background: '#F1F1F1',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 20,
		flex: 1,
	},
	cardTitle: {
		height: 76,
		borderBottom: '1px solid #CCCCCC',
		fontWeight: '500',
		fontSize: 18,
		color: '#000',
		paddingLeft: 26,
		paddingRight: 26,
		alignItems: 'center',
		display: 'flex',
	},
	cardBody: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingLeft: 26,
		paddingRight: 26,
	},
	itemWrapper: {
		marginRight: 50,
		marginTop: 10,
	},
	itemLabel: {
		fontSize: 12,
		fontWeight: 'normal',
		color: '#000',
		textTransform: 'capitalize',
		whiteSpace: 'nowrap',
	},
	itemValue: {
		marginTop: 6,
		fontSize: 18,
		fontWeight: '500',
		color: '#000',
		textTransform: 'capitalize',
	},
};
export default AccountDisplay;
