import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'shards-react';
import PasswordAndCommentModal, { useToggleModal } from '../../../components/common/PasswordAndCommentModal';
import moment from 'moment';
import ReactTable from 'react-table';
import API from '../../../data/API';

const BusinessAchDisplay = ({ aches, onRefresh, businessProfileId, externalAccounts }) => {
	const [action, setAction] = useState('');
	const [achId, setAchId] = useState('');
	const [counterpartyId, setCounterpartyId] = useState('');

	const [open, toggle] = useToggleModal();

	const approveCounterparty = async (password, comment) => {
		await API.post(`/banking-admin/business/${businessProfileId}/counterparty/manual-verification`, {
			counterpartyId,
			password,
			comment,
		});
		onRefresh();
	};

	const denyCounterparty = async (password, comment) => {
		await API.post(`/banking-admin/business/${businessProfileId}/counterparty/manual-denial`, {
			counterpartyId,
			password,
			comment,
		});
		onRefresh();
	};

	const cancelAch = async (password, comment) => {
		await API.post(`/banking-admin/business/cancel-ach/`, {
			achId,
			businessProfileId,
			password,
			comment,
		});
		onRefresh();
	};

	const submitAction = async (password, comment) => {
		switch (action) {
			case 'cancelAch':
				cancelAch(password, comment);
				break;
			case 'verifyCounterparty':
				approveCounterparty(password, comment);
				break;
			case 'denyCounterparty':
				denyCounterparty(password, comment);
				break;
			default:
				break;
		}
	};

	const externalAccountsTransfersHeader = [
		{
			Header: 'Account',
			accessor: 'displayName',
			className: 'text-left d-flex align-items-left',
			Cell: row => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						flex: 1,
					}}>
					<div>{row.original?.displayName}</div>
					{row.original.namesOnAccount.length > 0 && <div>{row.original.namesOnAccount.join(', ')}</div>}
				</div>
			),
		},
		{
			Header: 'Status',
			accessor: 'status',
			className: 'text-left d-flex align-items-center',
			maxWidth: 160,
		},
		{
			id: 'id',
			Header: 'Action',
			accessor: 'id',
			className: 'text-left d-flex align-items-center',
			maxWidth: 120,
			Cell: row =>
				row.original.status === 'needs-verification' ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							userSelect: 'none',
						}}>
						<div
							className="card-action"
							onClick={() => {
								setCounterpartyId(row.original.id);
								setAction('verifyCounterparty');
								toggle();
							}}
							style={{
								textDecorationLine: 'underline',
								cursor: 'pointer',
								padding: 5,
							}}>
							Approve
						</div>
						<div
							className="card-action"
							onClick={() => {
								setCounterpartyId(row.original.id);
								setAction('denyCounterparty');
								toggle();
							}}
							style={{
								textDecorationLine: 'underline',
								cursor: 'pointer',
								padding: 5,
							}}>
							Deny
						</div>
					</div>
				) : null,
		},
	];
	const achTransfersHeader = [
		{
			Header: 'Action By',
			accessor: 'actionBy.email',
			className: 'text-left d-flex align-items-center',
			// maxWidth: 120,
		},
		{
			Header: 'Description',
			accessor: 'description',
			className: 'text-left d-flex align-items-center',
			maxWidth: 120,
		},
		{
			Header: 'Direction',
			accessor: 'direction',
			className: 'text-left d-flex align-items-center',
			maxWidth: 80,
		},
		{
			id: 'amount',
			Header: 'Amount ($)',
			accessor: row => (row.amount / 100).toFixed(2),
			className: 'text-left d-flex align-items-center',
			maxWidth: 80,
		},
		{
			Header: 'Status',
			accessor: 'status',
			className: 'text-left d-flex align-items-center',
			maxWidth: 80,
		},
		{
			Header: 'Account Type',
			accessor: 'accountType',
			className: 'text-left d-flex align-items-center',
			maxWidth: 100,
		},
		// {
		// 	id: 'createdAt',
		// 	Header: 'Created',
		// 	accessor: row => moment(row.createdAt).format('MM/DD/YYYY'),
		// 	className: 'text-left d-flex align-items-center',
		// 	// maxWidth: 120,
		// },
		{
			id: 'updatedAt',
			Header: 'Updated',
			accessor: row => moment(row.updatedAt).format('MM/DD/YYYY'),
			className: 'text-left d-flex align-items-center',
			// maxWidth: 120,
		},
		{
			id: 'id',
			Header: 'Action',
			accessor: 'id',
			className: 'text-left d-flex align-items-center',
			// maxWidth: 120,
			Cell: row =>
				row.original.status === 'pending' ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							userSelect: 'none',
						}}>
						<div
							className="card-action"
							onClick={() => {
								setAchId(row.original.id);
								setAction('cancelAch');
								toggle();
							}}
							style={{
								textDecorationLine: 'underline',
								cursor: 'pointer',
								padding: 5,
							}}>
							Cancel Ach
						</div>
					</div>
				) : null,
		},
	];

	return (
		<>
			<PasswordAndCommentModal actionLabel={action} onConfirm={submitAction} toggle={open} />

			{externalAccounts && externalAccounts.length > 0 && (
				<Row className="py-4 d-flex justify-content-between">
					<Col lg="12" md="12">
						<Card small className="mb-3">
							<CardHeader className="border-bottom d-flex justify-content-between align-items-center">
								<h6 className="m-0">External Accounts</h6>
							</CardHeader>

							<CardBody className="p-0">
								<Row className="p-0 d-flex justify-content-between">
									<Col lg="12" md="12">
										<ReactTable
											columns={externalAccountsTransfersHeader}
											data={externalAccounts}
											resizable={false}
											usePagination={false}
											showPagination={false}
											defaultPageSize={externalAccounts.length}
										/>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}

			{aches && aches.length > 0 ? (
				<Row className="py-4 d-flex justify-content-between">
					<Col lg="12" md="12">
						<Card small className="mb-3">
							<CardHeader className="border-bottom d-flex justify-content-between align-items-center">
								<h6 className="m-0">Ach transactions</h6>
								{/* {isFlaggedForFraud ? (
									<Button
										style={{ margin: 5 }}
										onClick={() => {
											setAction('cancelAches');
											confirmModalToggle();
										}}
										theme="danger"
										disabled={aches.every(ach => ach.status !== 'pending')}>
										Cancel pending aches by this user
									</Button>
								) : null} */}
							</CardHeader>

							<CardBody className="p-0">
								<Row className="p-0 d-flex justify-content-between">
									<Col lg="12" md="12">
										<ReactTable
											getTdProps={() => ({
												style: { borderLeft: `none`, borderRight: `none` },
											})}
											columns={achTransfersHeader}
											data={aches}
											resizable={false}
											usePagination={false}
											defaultPageSize={aches.length}
											showPagination={false}
										/>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			) : null}
		</>
	);
};

export default BusinessAchDisplay;
