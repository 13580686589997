import React, { useState, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	ButtonGroup,
	Button,
	InputGroupAddon,
	InputGroup,
	FormInput,
} from 'shards-react';
import { Subscribe } from 'unstated';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import RequirePermissions from '../../components/RequirePermissions';

import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import ReactTable from 'react-table';
import moment from 'moment';
import { currency } from '../../utils/money';

const AccountsList = ({ history }) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pages, setPages] = useState(-1);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(20);
	const [order, setOrder] = useState('DESC');
	const [searchString, setSearchString] = useState('');

	useEffect(() => {
		(async () => {
			await getAccountsList();
		})();
	}, [order]);

	const tableColumns = [
		{
			id: 'createdAt',
			Header: 'Created',
			accessor: row => moment(row.createdAt).format('MM/DD/YYYY'),
			className: 'text-center d-flex align-items-center',
			maxWidth: 120,
			sortable: false,
		},
		{
			Header: 'Type',
			accessor: 'type',
			className: 'text-center d-flex align-items-center',
			maxWidth: 120,
			sortable: false,
		},
		{
			Header: 'Available Balance',
			accessor: 'availableBalance',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 100,
			sortable: true,
			Cell: row => <div>{currency(row.original.availableBalance / 100)}</div>,
		},
		{
			Header: 'Business Associated',
			accessor: 'businessProfile',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 100,
			sortable: false,
			Cell: row => <div>{row.original.businessProfile.description}</div>,
		},
		{
			Header: 'Affiliate Source',
			accessor: 'affiliate',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 100,
			sortable: false,
			Cell: row => <div>{row.original.affiliate?.displayName}</div>,
		},
	];

	const getAccountsList = async (tablePage, tablePageSize) => {
		if (loading) {
			return;
		}
		if (!tablePage) {
			setPage(0);
		}
		setLoading(true);
		const response = await API.get(
			`/banking-admin/account/list?page=${tablePage || 0}&pageSize=${
				tablePageSize || pageSize
			}&orderBy=${order}&displayName=${searchString}`,
		);
		if (response.data) {
			setData(response.data.data);
			setPages(response.data.totalPages);
		} else {
			setData([]);
			setPages(-1);
		}
		setLoading(false);
	};

	const onKeyUp = event => {
		if (event.key === 'Enter') {
			getAccountsList();
		}
	};

	return (
		<Container className="page-container">
			<RequirePermissions permissions="business.read" showRequiredPermissions>
				<Subscribe to={[Session]}>
					{({ state: { user } }) => {
						if (!user) {
							return <ScreenLoader />;
						} else {
							return (
								<div>
									<Row noGutters className="page-header py-4 d-flex justify-content-between">
										<PageTitle
											title="Accounts"
											subtitle="Banking"
											className="text-sm-left mb-3 p-0"
										/>
									</Row>
									<Row>
										<Col lg="12" md="12">
											<Card small className="mb-3">
												<CardHeader className="border-bottom d-flex flex-row justify-content-end">
													<div
														style={{
															// minWidth: 260 + 200,
															maxWidth: 380 + 200,
															alignSelf: 'flex-end',
														}}>
														<InputGroup
															seamless={true}
															className="d-flex flex-row justify-content-end">
															{/* <ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 ">
															<Button
																onClick={() => setOrder('DESC')}
																active={order === 'DESC'}
																theme="white">
																desc
															</Button>
															<Button
																onClick={() => setOrder('ASC')}
																active={order === 'ASC'}
																theme="white">
																asc
															</Button>
														</ButtonGroup> */}
															<FormInput
																placeholder="Search"
																type="search"
																value={searchString}
																onChange={event => {
																	if (loading) {
																		return;
																	}
																	setSearchString(event.target.value);
																}}
																onKeyUp={onKeyUp}
																style={{ marginLeft: 10 }}
															/>
															<InputGroupAddon type="append">
																<Button
																	onClick={async () => {
																		await getAccountsList();
																	}}
																	theme="secondary">
																	Search
																</Button>
															</InputGroupAddon>
														</InputGroup>
													</div>
												</CardHeader>

												<CardBody className="p-0">
													<ReactTable
														className="-highlight"
														NoDataComponent={() =>
															data.length <= 0 && !loading ? (
																<div className="rt-noData">No result found</div>
															) : null
														}
														loadingText="Searching"
														loading={loading}
														columns={tableColumns}
														data={data}
														resizable={false}
														usePagination={true}
														manual
														pages={pages}
														page={page}
														onFetchData={async (state, instance) => {
															if (state.sorted.length > 0) {
																setOrder(state.sorted[0].desc ? 'DESC' : 'ASC');
															}
															setPage(state.page);
															setPageSize(state.pageSize);
															setLoading(true);
															await getAccountsList(state.page, state.pageSize);
															setLoading(false);
														}}
														getTdProps={() => ({
															style: { borderLeft: `none`, borderRight: `none` },
														})}
														getTrProps={(state, rowInfo) => {
															if (rowInfo && rowInfo.row) {
																return {
																	onClick: e => {
																		history.push(
																			`/profile/business-banking-admin/details/${rowInfo.original.businessProfile.id}`,
																		);
																	},
																	style: {
																		cursor: 'pointer',
																	},
																};
															} else {
																return {};
															}
														}}
													/>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</div>
							);
						}
					}}
				</Subscribe>
			</RequirePermissions>
		</Container>
	);
};

export default AccountsList;
