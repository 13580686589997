import _ from 'lodash';
import { checkPermissionsForUser } from '../components/RequirePermissions';

export default () => {
	if (window.location.href.indexOf('/channel/') > -1) {
		return [
			app => ({
				title: 'Home',
				htmlBefore: '<i class="material-icons">home</i>',
				to: `/profile`,
			}),
			app => ({
				title: 'Checklist',
				htmlBefore: '<i class="material-icons">check</i>',
				to: app ? `/channel/${app.appCode}/activation-checklist` : '',
				display: !!app && !app.verified,
			}),
			app => ({
				title: 'Dashboard',
				htmlBefore: '<i class="material-icons">bar_chart</i>',
				to: app ? `/channel/${app.appCode}/overview` : '',
			}),
			app => ({
				title: 'Audio',
				htmlBefore: '<i class="material-icons">album</i>',
				to: app ? `/channel/${app.appCode}/albums` : '',
			}),
			app => ({
				title: 'Video',
				htmlBefore: '<i class="material-icons">videocam</i>',
				to: app ? `/channel/${app.appCode}/videos` : '',
				display: app && _.get(app.optionalFeatures, 'video.enabled') === true,
			}),
			app => ({
				title: 'Posts',
				htmlBefore: '<i class="material-icons">insert_photo</i>',
				to: app ? `/channel/${app.appCode}/posts` : '',
			}),
			app => ({
				title: 'Store',
				htmlBefore: '<i class="material-icons">shopping_bag</i>',
				to: app ? `/channel/${app.appCode}/store` : '',
				display: app && _.get(app.optionalFeatures, 'store.enabled') === true,
			}),
			app => ({
				title: 'Splits',
				htmlBefore: '<i class="material-icons">money</i>',
				to: app ? `/channel/${app.appCode}/splits` : '',
			}),
			app => ({
				title: 'About',
				htmlBefore: '<i class="material-icons">color_lens</i>',
				to: app ? `/channel/${app.appCode}/about` : '',
			}),
		];
	}
	if (window.location.href.indexOf('/npo/') > -1) {
		return [
			npo => ({
				title: 'Checklist',
				htmlBefore: '<i class="material-icons">check</i>',
				to: npo ? `/npo/${npo._id}/activation-checklist` : '',
				display: npo && !npo.verified,
			}),
			npo => ({
				title: 'Profile',
				htmlBefore: '<i class="material-icons">color_lens</i>',
				to: npo ? `/npo/${npo._id}/profile` : '',
			}),
			npo => ({
				title: 'Bank',
				htmlBefore: '<i class="material-icons">account_balance</i>',
				to: npo ? `/npo/${npo._id}/bank` : '',
			}),
		];
	}
	if (window.location.href.indexOf('/profile') > -1) {
		return [
			() =>
				checkPermissionsForUser(['user.read'])
					? {
							title: 'Users',
							htmlBefore: '<i class="material-icons">people</i>',
							to: `/profile/banking-admin`,
					  }
					: null,
			() =>
				checkPermissionsForUser(['business.read'])
					? {
							title: 'Businesses',
							htmlBefore: '<i class="material-icons">business</i>',
							to: `/profile/business-banking-admin`,
					  }
					: null,
			() =>
				checkPermissionsForUser(['business.read'])
					? {
							title: 'Accounts',
							htmlBefore: '<i class="material-icons">account_balance</i>',
							to: `/profile/accounts`,
					  }
					: null,
			() =>
				checkPermissionsForUser(['report.read'])
					? {
							title: 'Reports',
							htmlBefore: '<i class="material-icons">assessment</i>',
							to: `/profile/reports`,
					  }
					: null,
			() =>
				checkPermissionsForUser(['affiliate.read'])
					? {
							title: 'Affiliates',
							htmlBefore: '<i class="material-icons">people</i>',
							to: `/profile/affiliates`,
					  }
					: null,
		];
	}
	return [];
};
