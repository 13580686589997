import React, { useState, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	ButtonGroup,
	Button,
	InputGroupAddon,
	InputGroup,
	FormInput,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'shards-react';
import { Subscribe } from 'unstated';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import RequirePermissions from '../../components/RequirePermissions';

import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import ReactTable from 'react-table';
import moment from 'moment';

const labelType = ['All users', 'Whitelabel', 'Affiliate'];

const Reports = ({ history }) => {
	const [labelTypeOpen, setLabelTypeOpen] = useState(false);
	const [whitelabelDropdownOpen, setWhitelabelDropdownOpen] = useState(false);
	const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
	const [labels, setLabels] = useState([]);
	const [selectedLabel, setSelectedLabel] = useState({
		displayName: 'All users',
		id: 'All users',
	});
	const [selectedLabelType, setSelectedLabelType] = useState('All users');
	const [type, setType] = useState('weekly');
	const [reports, setReports] = useState(null);

	const [data, setData] = useState([]);

	const downloadReport = async (startDate, id) => {
		const downloadLink = `/banking-admin/report/${id}`;
		const data = await API.download(downloadLink);

		const fileName = `overview_report_${type}_${moment(startDate).format('YYYY-MM-DD')}.csv`;

		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
	};

	useEffect(() => {
		if (reports && selectedLabel && type && reports[selectedLabel.id] && reports[selectedLabel.id][type]) {
			setData(reports[selectedLabel.id][type]);
		}
	}, [reports, selectedLabel, type]);

	useEffect(() => {
		if (labels && labels[selectedLabelType] && labels[selectedLabelType][0]) {
			setSelectedLabel(labels[selectedLabelType][0]);
		}
	}, [selectedLabelType]);

	const getReportList = async () => {
		const { reports, labels } = await API.get(`/banking-admin/report/list`);
		setLabels(labels);
		setReports(reports);
	};

	const tableColumns = [
		{
			id: 'startDate',
			Header: 'Start Date',
			accessor: row => moment(row.startDate).format('YYYY-MM-DD'),
			className: 'text-center d-flex align-items-center',
			width: '50%',
			sortable: false,
		},
		{
			Header: 'Download',
			accessor: '_id',
			className: 'text-center d-flex align-items-center',
			width: '50%',
			sortable: false,
			Cell: row => (
				<div
					style={{ width: '100%', cursor: 'pointer' }}
					theme="light"
					onClick={() => {
						downloadReport(row.original.startDate, row.original._id);
					}}>
					Download
				</div>
			),
		},
	];

	const toggleWhiteLabelDropdown = () => {
		setWhitelabelDropdownOpen(!whitelabelDropdownOpen);
	};
	const toggleTypeDropdown = () => {
		setTypeDropdownOpen(!typeDropdownOpen);
	};
	const toggleLabelTypeDropdown = () => {
		setLabelTypeOpen(!labelTypeOpen);
	};
	useEffect(() => {
		(async () => {
			await getReportList();
		})();
	}, []);
	return (
		<Container className="page-container">
			<RequirePermissions permissions={['report.read']} showRequiredPermissions>
				<Subscribe to={[Session]}>
					{({ state: { user } }) => {
						if (!user) {
							return <ScreenLoader />;
						} else {
							return (
								<div>
									<Row noGutters className="page-header py-4 d-flex justify-content-between">
										<PageTitle
											title="Reports"
											// subtitle="Banking"
											className="text-sm-left mb-3 p-0"
										/>
									</Row>
									<Row>
										<Col lg="12" md="12">
											<Card small className="mb-3">
												<CardHeader className="border-bottom d-flex flex-row justify-content-start">
													<Dropdown
														open={labelTypeOpen}
														toggle={toggleLabelTypeDropdown}
														className="mr-2">
														<DropdownToggle theme={selectedLabelType ? 'success' : 'light'}>
															{selectedLabelType}
														</DropdownToggle>
														<DropdownMenu>
															{labelType.map((type, index) => {
																return (
																	<DropdownItem
																		onClick={() => {
																			setSelectedLabelType(type);
																		}}
																		key={index}>
																		{type}
																	</DropdownItem>
																);
															})}
														</DropdownMenu>
													</Dropdown>

													{selectedLabelType !== 'All users' && (
														<Dropdown
															open={whitelabelDropdownOpen}
															toggle={toggleWhiteLabelDropdown}
															className="mr-2">
															<DropdownToggle
																theme={
																	selectedLabel && selectedLabel?.displayName
																		? 'success'
																		: 'light'
																}>
																{selectedLabel?.displayName}
															</DropdownToggle>
															<DropdownMenu>
																{labels[selectedLabelType].map((label, index) => {
																	return (
																		<DropdownItem
																			onClick={() => {
																				setSelectedLabel(label);
																			}}
																			key={index}>
																			{label?.displayName}
																		</DropdownItem>
																	);
																})}
															</DropdownMenu>
														</Dropdown>
													)}

													<Dropdown open={typeDropdownOpen} toggle={toggleTypeDropdown}>
														<DropdownToggle theme="success">{type}</DropdownToggle>
														<DropdownMenu>
															{['weekly', 'monthly'].map((type, index) => {
																return (
																	<DropdownItem
																		onClick={() => {
																			setType(type);
																		}}
																		key={index}>
																		{type}
																	</DropdownItem>
																);
															})}
														</DropdownMenu>
													</Dropdown>
												</CardHeader>

												<CardBody className="p-0">
													<ReactTable
														getTdProps={() => ({
															style: { justifyContent: 'flex-start' },
														})}
														className="-highlight"
														data={data}
														columns={tableColumns}
														resizable={false}
														usePagination={false}
														showPaginationBottom={false}
													/>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</div>
							);
						}
					}}
				</Subscribe>
			</RequirePermissions>
		</Container>
	);
};

export default Reports;
