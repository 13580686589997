import React, { useState, useEffect, useRef } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Form,
	FormGroup,
	FormInput,
	Button,
	Alert,
	Modal,
	ModalBody,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from 'shards-react';
import { Subscribe } from 'unstated';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import ReactTable from 'react-table';
import RequirePermissions from '../../components/RequirePermissions';

const AffiliateDetails = ({ history, match }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});

	const [submiting, setSubmiting] = useState(false);
	const [newSlug, setNewSlug] = useState('');
	const [displayName, setDisplayName] = useState('');
	const [showLinkCopiedAlert, setShowLinkCopiedAlert] = useState(false);
	const [newLinkModalOpen, setNewLinkModalOpen] = useState(false);
	const [displayNameModalOpen, setDisplayNameModalOpen] = useState(false);
	const timeoutRef = useRef(null);

	const tableColumns = [
		{
			sortable: false,
			accessor: '',
			Header: '',
			className: 'd-flex align-items-center justify-content-start',
			// maxWidth: 100,
			Cell: row => (
				<div>
					{process.env.REACT_APP_AFFILIATE_PREFIX_URL}/{row.original}
				</div>
			),
		},
		{
			sortable: false,
			Header: '',
			className: 'text-center d-flex align-items-center',
			maxWidth: 150,
			Cell: row => (
				<div
					className="copy-link"
					onClick={() => copyLink(row.original)}
					style={{
						textDecorationLine: 'underline',
						cursor: 'pointer',
					}}>
					Copy Link
				</div>
			),
		},
	];

	const toggleNewLinkModal = () => {
		resetEdit();
		setNewLinkModalOpen(!newLinkModalOpen);
	};
	const toggleDisplayNameModal = () => {
		resetEdit();
		setDisplayNameModalOpen(!displayNameModalOpen);
	};

	const copyLink = async link => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		await navigator.clipboard.writeText(`${process.env.REACT_APP_AFFILIATE_PREFIX_URL}/${link}`);
		setShowLinkCopiedAlert(true);
		timeoutRef.current = setTimeout(() => {
			setShowLinkCopiedAlert(false);
		}, 2000);
	};

	const onRefresh = async () => {
		setLoading(true);
		await getDetail();
		setLoading(false);
	};

	const resetEdit = () => {
		setNewSlug('');
		setDisplayName('');
	};

	const getDetail = async () => {
		try {
			const response = await API.get(`/banking-admin/affiliate/${match.params.affiliateId}`);
			setData(response.data);
			resetEdit();
		} catch (error) {
			console.error('Error fetching affiliate details:', error);
		}
	};

	const addNewSlug = async () => {
		setSubmiting(true);
		try {
			await API.put(`/banking-admin/affiliate/${match.params.affiliateId}/update/slug`, {
				value: newSlug,
			});
			await getDetail();
		} catch (error) {
			console.error('Error adding new slug:', error);
		}
		toggleNewLinkModal();
		setSubmiting(false);
	};

	const updateDisplayName = async () => {
		setSubmiting(true);
		try {
			await API.put(`/banking-admin/affiliate/${match.params.affiliateId}/update/displayName`, {
				displayName,
			});
			await getDetail();
		} catch (error) {
			console.error('Error updating associate:', error);
		}
		toggleDisplayNameModal();
		setSubmiting(false);
	};

	useEffect(() => {
		(async () => {
			await onRefresh();
		})();
	}, []);

	return (
		<Container className="page-container">
			<Subscribe to={[Session]}>
				{({ state: { user } }) => {
					if (!user) {
						return <ScreenLoader />;
					} else {
						return (
							<div>
								<Row noGutters className="page-header py-4 d-flex justify-content-between">
									<PageTitle
										title={data?.displayName || 'Affiliate Details'}
										subtitle="ALL AFFILIATES"
										className="text-sm-left mb-3 p-0"
										subtitleOnClick={() => history.push(`/profile/affiliates`)}
									/>
								</Row>

								{loading ? (
									<ScreenLoader />
								) : (
									<>
										<RequirePermissions permissions={['affiliate.read']}>
											<Row className="py-4 d-flex justify-content-between">
												<Col lg="12" md="12">
													<Modal open={displayNameModalOpen} toggle={toggleDisplayNameModal}>
														<ModalBody>
															<Col lg="12" md="12">
																<Row className="px-3">
																	<Col lg="12" md="12">
																		<RequirePermissions
																			permissions={['affiliate.write']}>
																			<Row className="px-3">
																				<Col lg="12" md="12">
																					<Form
																						onSubmit={e =>
																							e.preventDefault()
																						}>
																						<FormGroup>
																							<label htmlFor="displayName">
																								Display name:{' '}
																							</label>
																							<FormInput
																								id="displayName"
																								type="text"
																								value={displayName}
																								onChange={event =>
																									setDisplayName(
																										event.target
																											.value,
																									)
																								}
																							/>
																						</FormGroup>
																						<Button
																							pill
																							theme="accent"
																							className="d-table mx-auto"
																							onClick={updateDisplayName}
																							loading={submiting}
																							disabled={!displayName}>
																							Update
																						</Button>
																					</Form>
																				</Col>
																			</Row>
																		</RequirePermissions>
																	</Col>
																</Row>
															</Col>
														</ModalBody>
													</Modal>
													<Card small className="mb-3">
														<CardHeader className="border-bottom d-flex flex-row justify-content-between align-items-center">
															<h6 className="m-0">Affiliate Details</h6>

															<RequirePermissions permissions={['affiliate.write']}>
																<div
																	style={{
																		minWidth: 260,
																		maxWidth: 380,
																		alignSelf: 'flex-end',
																		display: 'flex',
																		justifyContent: 'flex-end',
																	}}>
																	<Button
																		onClick={toggleDisplayNameModal}
																		theme="secondary">
																		Update Display Name
																	</Button>
																</div>
															</RequirePermissions>
														</CardHeader>
														<CardBody>
															<Row className="px-3">
																<div style={styles.itemWrapper}>
																	<div style={styles.itemLabel}>Type:</div>
																	<div style={styles.itemValue}>
																		{data.associatedType}
																	</div>
																</div>
																<div style={styles.itemWrapper}>
																	<div style={styles.itemLabel}>
																		{data.associatedType} ID:
																	</div>
																	<div style={styles.itemValue}>
																		{data.associatedId}
																	</div>
																</div>
															</Row>
														</CardBody>
													</Card>
												</Col>
											</Row>
											<Row>
												<Col lg="12" md="12">
													<Alert theme="success" open={showLinkCopiedAlert}>
														Affiliate link copied to clipboard
													</Alert>

													<Modal open={newLinkModalOpen} toggle={toggleNewLinkModal}>
														<ModalBody>
															<Col lg="12" md="12">
																<Row className="px-3">
																	<Col lg="12" md="12">
																		<RequirePermissions
																			permissions={['affiliate.write']}>
																			<Row className="px-3">
																				<Col lg="12" md="12">
																					<Form
																						onSubmit={e =>
																							e.preventDefault()
																						}>
																						<FormGroup>
																							<label htmlFor="addNewSlug">
																								Add new link:{' '}
																							</label>
																							<InputGroup>
																								<InputGroupAddon type="prepend">
																									<InputGroupText>
																										{
																											process.env
																												.REACT_APP_AFFILIATE_PREFIX_URL
																										}
																										/
																									</InputGroupText>
																								</InputGroupAddon>
																								<FormInput
																									id="addNewSlug"
																									type="text"
																									value={newSlug}
																									onChange={event =>
																										setNewSlug(
																											event.target
																												.value,
																										)
																									}
																								/>
																							</InputGroup>
																						</FormGroup>
																						<Button
																							pill
																							theme="accent"
																							className="d-table mx-auto"
																							onClick={addNewSlug}
																							loading={submiting}
																							disabled={!newSlug}>
																							Add
																						</Button>
																					</Form>
																				</Col>
																			</Row>
																		</RequirePermissions>
																	</Col>
																</Row>
															</Col>
														</ModalBody>
													</Modal>
													<Card className="mb-3">
														<CardHeader className="d-flex flex-row justify-content-between align-items-center">
															<h6 className="m-0">Links</h6>

															<RequirePermissions permissions={['affiliate.write']}>
																<div
																	style={{
																		minWidth: 260,
																		maxWidth: 380,
																		alignSelf: 'flex-end',
																		display: 'flex',
																		justifyContent: 'flex-end',
																	}}>
																	<Button
																		onClick={toggleNewLinkModal}
																		theme="secondary">
																		Add New Link
																	</Button>
																</div>
															</RequirePermissions>
														</CardHeader>
														<CardBody className="p-0">
															<ReactTable
																getTdProps={() => ({
																	style: {
																		borderLeft: `none`,
																		borderRight: `none`,
																	},
																})}
																getTheadThProps={() => ({
																	style: {
																		display: 'none',
																	},
																})}
																className="-highlight"
																NoDataComponent={() =>
																	data.length <= 0 && !loading ? (
																		<div className="rt-noData">No result found</div>
																	) : null
																}
																loadingText="Loading"
																loading={loading}
																columns={tableColumns}
																data={data.slugHistory}
																resizable={false}
																manual
																usePagination={false}
																showPaginationBottom={false}
															/>
														</CardBody>
													</Card>
												</Col>
											</Row>
										</RequirePermissions>
									</>
								)}
							</div>
						);
					}
				}}
			</Subscribe>
		</Container>
	);
};

const styles = {
	cardContainer: {
		// minWidth: 558,
		height: 200,
		background: '#F1F1F1',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 20,
		flex: 1,
	},
	cardTitle: {
		height: 76,
		borderBottom: '1px solid #CCCCCC',
		fontWeight: '500',
		fontSize: 18,
		color: '#000',
		paddingLeft: 26,
		paddingRight: 26,
		alignItems: 'center',
		display: 'flex',
	},
	cardBody: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingLeft: 26,
		paddingRight: 26,
	},
	itemWrapper: {
		marginRight: 50,
		marginTop: 10,
	},
	itemLabel: {
		fontSize: 12,
		fontWeight: 'normal',
		color: '#000',
		textTransform: 'capitalize',
		whiteSpace: 'nowrap',
	},
	itemValue: {
		marginTop: 6,
		fontSize: 18,
		fontWeight: '500',
		color: '#000',
		textTransform: 'capitalize',
	},
};

export default AffiliateDetails;
