import React, { useState, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Button,
	ButtonGroup,
	CardHeader,
	Modal,
	ModalBody,
	ModalHeader,
	FormSelect,
} from 'shards-react';
import { Subscribe } from 'unstated';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import ReactTable from 'react-table';
import BusinessInfoDisplay from './components/BusinessInfoDisplay';
import moment from 'moment';
import ReactJson from 'react-json-view';
import AccountDisplay from './components/AccountDisplay';
import OwnersDisplay from './components/OwnersDisplay';
import BusinessAchDisplay from './components/BusinessAchDisplay';
import SocialTabDisplay from './components/SocialTabDisplay';
import FeaturesTabDisplay from './components/FeaturesTabDisplay';
import AddAuditItem from './components/AddAuditItem';

const convertCentToString = value => '$' + (value / 100).toFixed(2);

const BankingAdminBusinessDetails = ({ history, match }) => {
	const [currentTab, setCurrentTab] = useState(null);
	const [name, setName] = useState(null);
	const [loading, setLoading] = useState(false);
	const [audit, setAudit] = useState([]);
	const [open, setOpen] = useState(false);
	const [detailsTitle, setDetailsTitle] = useState('');
	const [detailsBody, setDetailsBody] = useState('');
	const [businessInfoVerified, setBusinessInfoVerified] = useState(null);
	const [businessInfo, setBusinessInfo] = useState({});
	const [checking, setChecking] = useState(null);
	const [savings, setSavings] = useState(null);
	const [owners, setOwners] = useState([]);
	const [aches, setAches] = useState([]);
	const [shouldShowBusinessInfo, setShouldShowBusinessInfo] = useState(false);
	const [socialProfiles, setSocialProfiles] = useState([]);
	const [externalAccounts, setExternalAccounts] = useState([]);

	const toggle = () => {
		setOpen(!open);
	};

	const tableColumns = [
		{
			id: 'date',
			Header: 'Date',
			accessor: row => moment(row.date).format('MM/DD/YYYY'),
			className: 'text-center d-flex align-items-center',
			maxWidth: 120,
		},

		{
			Header: 'Message',
			accessor: 'message',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 120,
		},
		{
			Header: 'Action Taken By',
			accessor: 'actionTakenBy',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 120,
		},
		{
			Header: 'Detail Type',
			accessor: 'detailType',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 120,
		},
		{
			Header: '',
			accessor: 'payload',
			className: 'text-center d-flex align-items-center',
			maxWidth: 100,
			Cell: row => (
				<div>
					{(row.original.payload || row.original.comment) && row.original.detailType !== 'personalInfo' ? (
						<span
							onClick={() => {
								setDetailsTitle(row.original.detailType);
								setDetailsBody({
									comment: row.original.comment,
									payload: row.original.payload,
								});
								toggle();
							}}
							style={{
								cursor: 'pointer',
								textDecorationLine: 'underline',
							}}>
							Details
						</span>
					) : null}
				</div>
			),
		},
	];

	const getBusinessAudits = async () => {
		setLoading(true);
		const response = await API.get(`/banking-admin/business/audits/business/${match.params.businessProfileId}`);
		if (response.audits) {
			setAudit(response.audits);
		}
		setLoading(false);
	};

	const getPlatformProfiles = async () => {
		setLoading(true);
		const response = await API.get(`/banking-admin/phyllo/${match.params.businessProfileId}/profiles`);
		if (response) {
			setSocialProfiles(response);
		}
		setLoading(false);
	};

	const refreshPage = async () => {
		setLoading(true);

		const response = await API.get(`/banking-admin/business/details/${match.params.businessProfileId}`);
		const { accounts, displayName, owners, businessInfo, businessInfoVerified, businessType, counterparties } =
			response.data;

		setShouldShowBusinessInfo(businessType && businessType !== 'collab' && businessType !== 'soleprop');

		const checking = accounts.find(account => account.type === 'checking');
		const savings = accounts.find(account => account.type === 'savings');

		const { aches } = await API.get(`/banking-admin/business/aches/${match.params.businessProfileId}`);

		setExternalAccounts(counterparties);
		setAches(aches);
		setOwners(owners);
		setName(displayName);
		setBusinessInfo(businessInfo);
		setBusinessInfoVerified(businessInfoVerified);
		setCurrentTab(checking ? 'checking' : savings ? 'savings' : 'owners');
		getPlatformProfiles();

		if (checking) {
			let accountActivity = [];
			const response = await API.get(`/banking-admin/business/transactions/${checking.id}`);
			if (response.data) {
				accountActivity = response.data.transactions.map(
					({ date, description, type, amount, originalDescription, note, label, pendingItemType }) => {
						return {
							date,
							description,
							type: pendingItemType ? 'Pending' : !type ? '' : type.toUpperCase(),
							amount: (amount / 100).toFixed(2),
							originalDescription,
							note,
							label,
						};
					},
				);
			}
			setChecking({
				type: 'checking',
				availableBalance: convertCentToString(checking.availableBalance),
				currentBalance: convertCentToString(checking.currentBalance),
				status: checking.status,
				created: checking.createdAt,
				accountActivity,
				last4OfAccountNumber: checking.last4OfAccountNumber,
			});
		}
		if (savings) {
			let accountActivity = [];
			const response = await API.get(`/banking-admin/business/transactions/${savings.id}`);
			if (response.data) {
				accountActivity = response.data.transactions.map(({ date, description, type, amount }) => {
					return {
						date,
						description,
						type: !type ? '' : type.toUpperCase(),
						amount: convertCentToString(amount),
					};
				});
			}
			setSavings({
				type: 'savings',
				availableBalance: convertCentToString(savings.availableBalance),
				currentBalance: convertCentToString(savings.currentBalance),
				status: savings.status,
				created: savings.createdAt,
				accountActivity,
				last4OfAccountNumber: savings.last4OfAccountNumber,
			});
		}

		setLoading(false);
	};

	useEffect(() => {
		(async () => {
			await refreshPage();
			const query = new URLSearchParams(history.location.search);
			const tab = query.get('initTab');
			tab && setCurrentTab(query.get('initTab'));
		})();
	}, []);

	return (
		<Container className="page-container">
			<Subscribe to={[Session]}>
				{({ state: { user } }) => {
					if (!user) {
						return <ScreenLoader />;
					} else {
						return (
							<div>
								<Modal open={open} toggle={toggle}>
									<ModalHeader>{detailsTitle}</ModalHeader>
									{detailsBody.comment ? (
										<ModalBody className="border-bottom">
											User comment:{' '}
											<span
												style={{
													fontWeight: 300,
												}}>
												{detailsBody.comment}
											</span>
										</ModalBody>
									) : null}
									<ModalBody>
										<ReactJson
											src={detailsBody.payload}
											theme="shapeshifter:inverted"
											collapsed={1}
											displayDataTypes={false}
											displayObjectSize={false}
											indentWidth={2}
											enableClipboard={false}
											name="data"
										/>
										{/* <pre>{detailsBody}</pre> */}
									</ModalBody>
								</Modal>
								<Row noGutters className="page-header py-4 d-flex justify-content-between">
									<PageTitle
										small={6}
										title={name || 'Customer'}
										subtitle="ALL BUSINESSES"
										className="text-sm-left mb-3 p-0"
										subtitleOnClick={() => history.push(`/profile/business-banking-admin/`)}
									/>

									<Col xs="4" className="col d-flex align-items-center">
										<ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
											{checking ? (
												<Button
													onClick={() => setCurrentTab('checking')}
													active={currentTab === 'checking'}
													theme="white">
													Checking
												</Button>
											) : null}
											{savings ? (
												<Button
													onClick={() => setCurrentTab('savings')}
													active={currentTab === 'savings'}
													theme="white">
													Savings
												</Button>
											) : null}
											{(aches.length > 0 || externalAccounts.length > 0) && (
												<Button
													onClick={() => setCurrentTab('ach')}
													active={currentTab === 'ach'}
													theme="white">
													Ach
												</Button>
											)}
											<Button
												onClick={() => setCurrentTab('owners')}
												active={currentTab === 'owners'}
												theme="white">
												Owners
											</Button>
											<Button
												onClick={() => {
													setCurrentTab('social');
												}}
												active={currentTab === 'social'}
												theme="white">
												Social ({socialProfiles.length})
											</Button>
											{shouldShowBusinessInfo ? (
												<Button
													onClick={() => setCurrentTab('businessInfo')}
													active={currentTab === 'businessInfo'}
													theme="white">
													Business Info
												</Button>
											) : null}

											<Button
												onClick={() => {
													setCurrentTab('features');
													getBusinessAudits();
												}}
												active={currentTab === 'features'}
												theme="white">
												Features
											</Button>
											<Button
												onClick={() => {
													setCurrentTab('audit');
													getBusinessAudits();
												}}
												active={currentTab === 'audit'}
												theme="white">
												Audit
											</Button>
										</ButtonGroup>
									</Col>
								</Row>

								{loading ? (
									<ScreenLoader />
								) : (
									<>
										<div style={{ marginBottom: 26 }}>
											{(checking && currentTab === 'checking') ||
											(savings && currentTab === 'savings') ? (
												<AccountDisplay
													businessProfileId={match.params.businessProfileId}
													account={currentTab === 'checking' ? checking : savings}
													onRefresh={async type => {
														await refreshPage();
														setCurrentTab(type);
													}}
												/>
											) : null}
										</div>
										{currentTab === 'owners' ? (
											<OwnersDisplay
												canOrderCard={
													checking &&
													checking.status !== 'closed' &&
													checking.status !== 'close_pending'
												}
												owners={owners}
												history={history}
												businessProfileId={match.params.businessProfileId}
												onRefresh={async () => {
													await refreshPage();
													setCurrentTab('owners');
												}}
											/>
										) : null}
										{currentTab === 'social' ? (
											<SocialTabDisplay profiles={socialProfiles} />
										) : null}
										{currentTab === 'ach' ? (
											<BusinessAchDisplay
												aches={aches}
												externalAccounts={externalAccounts}
												history={history}
												businessProfileId={match.params.businessProfileId}
												onRefresh={async () => {
													await refreshPage();
													setCurrentTab('ach');
												}}
											/>
										) : null}
										{currentTab === 'businessInfo' && shouldShowBusinessInfo ? (
											<BusinessInfoDisplay
												created={businessInfoVerified !== null}
												verified={businessInfoVerified}
												businessProfileId={match.params.businessProfileId}
												businessInfoBeforeVerified={businessInfo}
											/>
										) : null}
										{currentTab === 'features' ? (
											<FeaturesTabDisplay businessProfileId={match.params.businessProfileId} />
										) : null}
										{currentTab === 'audit' ? (
											<Row>
												<Col lg="12" md="12">
													<Card small className="mb-3">
														<CardHeader className="border-bottom">
															<Row
																style={{
																	justifyContent: 'space-between',
																	padding: '0px 15px',
																}}>
																<h6 className="m-0">Audit Trail</h6>
																<AddAuditItem
																	type="business"
																	id={match.params.businessProfileId}
																	onRefresh={async () => {
																		await refreshPage();
																		setCurrentTab('audit');
																		await getBusinessAudits();
																	}}
																/>
															</Row>
														</CardHeader>

														<CardBody className="p-0">
															<ReactTable
																getTdProps={() => ({
																	style: {
																		borderLeft: `none`,
																		borderRight: `none`,
																	},
																})}
																columns={tableColumns}
																data={audit}
																resizable={false}
																usePagination={true}
															/>
														</CardBody>
													</Card>
												</Col>
											</Row>
										) : null}
									</>
								)}
							</div>
						);
					}
				}}
			</Subscribe>
		</Container>
	);
};

export default BankingAdminBusinessDetails;
