import React, { useState, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	InputGroupAddon,
	InputGroup,
	FormInput,
} from 'shards-react';
import { Subscribe } from 'unstated';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import RequirePermissions from '../../components/RequirePermissions';

import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import ReactTable from 'react-table';
import moment from 'moment';

const UsersList = ({ history }) => {
	const [searchString, setSearchString] = useState('');
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pages, setPages] = useState(-1);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(20);

	useEffect(() => {
		(async () => {
			await search();
		})();
	}, []);

	const tableColumns = [
		{
			sortable: false,
			Header: '',
			Cell: row => (
				<div>
					<div
						style={{
							width: 10,
							height: 10,
							background: row.original.isFlaggedForFraud
								? '#E32727'
								: // : row.original.isReferrerFlaggedForFraud
								  // ? '#FEA20B'
								  'transparent',
							borderRadius: '50%',
						}}
					/>
				</div>
			),
			className: 'text-center d-flex align-items-center',
			maxWidth: 34,
			minWidth: 34,
		},
		{
			sortable: false,
			id: 'createdAt',
			Header: 'Created',
			accessor: row => moment(row.createdAt).format('MM/DD/YYYY'),
			className: 'text-center d-flex align-items-center',
			maxWidth: 100,
		},
		{
			Header: 'Affiliate Source',
			accessor: 'affiliate',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 100,
			sortable: false,
			Cell: row => <div>{row.original.affiliate?.displayName}</div>,
		},
		{
			sortable: false,
			Header: 'Email',
			accessor: 'email',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 120,
		},
		{
			sortable: false,
			Header: 'Personal Info',
			accessor: 'personalInfo',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 120,
		},
		{
			sortable: false,
			Header: 'Beta Access',
			accessor: 'betaAccess',
			className: 'text-center d-flex align-items-center',
			maxWidth: 100,
			Cell: row => (
				<div>
					{row.original.betaAccess ? (
						<img
							src={require('../../images/icons/check.png')}
							alt="icon_check"
							style={{
								width: 28,
								height: 28,
							}}
						/>
					) : (
						<div
							className="grant-access"
							onClick={() => grantAccess(row.original.userId)}
							style={{
								textDecorationLine: 'underline',
								cursor: 'pointer',
							}}>
							grant access
						</div>
					)}
				</div>
			),
		},
	];

	const grantAccess = async userId => {
		if (loading) {
			return;
		}
		setLoading(true);
		await API.post(`/banking-admin/user/grant-beta-access/`, {
			userId,
		});
		await search();
		setLoading(false);
	};

	const search = async (tablePage, tablePageSize) => {
		if (loading) {
			return;
		}
		if (!tablePage) {
			setPage(0);
		}
		setLoading(true);
		const response = await API.get(
			`/banking-admin/user/search/${searchString}?page=${tablePage || 0}&pageSize=${tablePageSize || pageSize}`,
		);
		if (response.data) {
			setData(response.data.data);
			setPages(response.data.totalPages);
		} else {
			setData([]);
			setPages(-1);
		}
		setLoading(false);
	};

	const onKeyUp = event => {
		if (event.key === 'Enter') {
			search();
		}
	};

	return (
		<Container className="page-container">
			<RequirePermissions permissions={['user.read']} showRequiredPermissions>
				<Subscribe to={[Session]}>
					{({ state: { user } }) => {
						if (!user) {
							return <ScreenLoader />;
						} else {
							return (
								<div>
									<Row noGutters className="page-header py-4 d-flex justify-content-between">
										<PageTitle title="Users" subtitle="Banking" className="text-sm-left mb-3 p-0" />

										{/* <Col xs="12" sm="4" className="col d-flex align-items-center">
    <ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
      <Button tag={NavLink} theme="white" active to="orders">
        Orders
      </Button>
      <Button tag={NavLink} theme="white" to="products">
        Products
      </Button>
    </ButtonGroup>
  </Col> */}
									</Row>
									<Row>
										<Col lg="12" md="12">
											<Card small className="mb-3">
												<CardHeader className="border-bottom d-flex flex-row justify-content-end">
													<div
														style={{
															minWidth: 260,
															maxWidth: 380,
															alignSelf: 'flex-end',
														}}>
														<InputGroup seamless={true}>
															<FormInput
																placeholder="Search"
																type="search"
																value={searchString}
																onChange={event => {
																	if (loading) {
																		return;
																	}
																	setSearchString(event.target.value);
																}}
																onKeyUp={onKeyUp}
															/>
															<InputGroupAddon type="append">
																<Button
																	onClick={async () => {
																		await search();
																	}}
																	theme="secondary">
																	Search
																</Button>
															</InputGroupAddon>
														</InputGroup>
													</div>
												</CardHeader>

												<CardBody className="p-0">
													<ReactTable
														className="-highlight"
														NoDataComponent={() =>
															data.length <= 0 && !loading ? (
																<div className="rt-noData">No result found</div>
															) : null
														}
														loadingText="Searching"
														loading={loading}
														columns={tableColumns}
														data={data}
														resizable={false}
														usePagination={true}
														manual
														pages={pages}
														page={page}
														onFetchData={async (state, instance) => {
															setPage(state.page);
															setPageSize(state.pageSize);
															setLoading(true);
															await search(state.page, state.pageSize);
															setLoading(false);
															console.log(state, instance);
														}}
														getTdProps={() => ({
															style: { borderLeft: `none`, borderRight: `none` },
														})}
														getTrProps={(state, rowInfo) => {
															if (rowInfo && rowInfo.row) {
																return {
																	onClick: e => {
																		if (e.target.className === 'grant-access') {
																			return;
																		}
																		history.push(
																			`/profile/banking-admin/details/${rowInfo.original.userId}`,
																		);
																	},
																	style: {
																		cursor: 'pointer',
																	},
																};
															} else {
																return {};
															}
														}}
													/>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</div>
							);
						}
					}}
				</Subscribe>
			</RequirePermissions>
		</Container>
	);
};

export default UsersList;
