import React, { useState, useEffect } from 'react';
import { Row, Col, FormTextarea, Button, Modal, ModalBody, ModalHeader, FormInput } from 'shards-react';

const BonusModal = ({ onConfirm, toggle = 0 }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [password, setPassword] = useState('');
	const [comment, setComment] = useState('');
	const [amountString, setAmountString] = useState('');
	const [error, setError] = useState('');

	const resetData = () => {
		setPassword('');
		setComment('');
		setAmountString('');
		setError('');
	};

	const modalToggle = () => {
		_toggle(!modalOpen);
	};

	const _toggle = value => {
		resetData();
		setModalOpen(value);
	};

	useEffect(() => {
		if (toggle > 0) {
			_toggle(true);
		}
	}, [toggle]);

	const onConfirmClick = async () => {
		try {
			const amountInCents = parseFloat(amountString) * 100;
			onConfirm && (await onConfirm(password, amountInCents, comment));
			modalToggle();
		} catch (error) {
			setError(error?.response?.data?.message || error?.message || 'An error occurred');
		}
	};

	return (
		<Modal open={modalOpen} toggle={modalToggle}>
			<ModalHeader>Pay Manual Bonus</ModalHeader>
			<ModalBody>
				<Row className="m-2">
					<Col md="12">
						<FormInput
							placeholder="Amount ($)"
							type={'text'}
							size="md"
							className="mb-3"
							value={amountString}
							onChange={event => setAmountString(event.target.value)}
							required
						/>
					</Col>
				</Row>

				<Row className="m-2">
					<Col md="12">
						<FormInput
							placeholder="Your Password"
							type={'password'}
							size="md"
							className="mb-3"
							value={password}
							onChange={event => setPassword(event.target.value)}
							required
						/>
					</Col>
				</Row>

				<Row className="m-2">
					<Col md="12">
						<FormTextarea
							size="lg"
							rows="8"
							value={comment}
							placeholder={`Comment`}
							onChange={event => setComment(event.target.value)}
							required={true}
						/>
					</Col>
				</Row>

				<Row className="m-2">
					<Col md="12" className="text-danger">
						{error}
					</Col>
				</Row>

				<Row className="m-2 d-flex justify-content-end">
					<Button style={{ margin: 5 }} onClick={modalToggle} theme="light">
						Cancel
					</Button>
					<Button
						style={{ margin: 5 }}
						onClick={onConfirmClick}
						disabled={!password || !comment || !amountString}>
						Pay
					</Button>
				</Row>
			</ModalBody>
		</Modal>
	);
};

export const useToggleModal = () => {
	const [count, setCount] = useState(0);
	const toggle = () => setCount(count + 1);

	return [count, toggle];
};

export default BonusModal;
