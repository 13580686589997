import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader, FormInput, FormRadio } from 'shards-react';
import PasswordAndCommentModal, { useToggleModal } from '../../../components/common/PasswordAndCommentModal';
import RequirePermissions from '../../../components/RequirePermissions';

import ReactTable from 'react-table';
import API from '../../../data/API';

const OwnersDisplay = ({ canOrderCard, owners, history, onRefresh, businessProfileId }) => {
	const [action, setAction] = useState('');
	const [selectedUserId, setSelectedUserId] = useState('');
	const [cardType, setCardType] = useState('');

	const [open, toggle] = useToggleModal();

	const onRowSlected = (id, cardProductType) => {
		setSelectedUserId(id);
		setCardType(cardProductType);
	};

	const renderOwnership = ({ title, isAdmin, isOptedIn, ownershipPercentage }) => {
		const adminTag = (
			<div style={{ ...styles.ownershipTag, ...{ backgroundColor: '#657C9D' } }}>
				<span>Admin</span>
			</div>
		);

		const optedInTag = (
			<div style={{ ...styles.ownershipTag, ...{ backgroundColor: '#86B74B' } }}>
				<span>Opted-in</span>
			</div>
		);

		return (
			<div style={styles.ownershipWrapper}>
				<div style={styles.ownershipRow}>
					<span style={styles.ownershipTitle}>{title ?? 'Sole Proprietor'}</span>
					{isAdmin && adminTag}
					{isOptedIn && optedInTag}
				</div>
				<div style={styles.ownershipPercentage}>
					<span>{ownershipPercentage} owner</span>
				</div>
			</div>
		);
	};

	const renderCardActionButton = (action, userId, cardType) => {
		return (
			<div
				className="card-action"
				onClick={() => {
					onRowSlected(userId, cardType);
					setAction(action);
					toggle();
				}}
				style={{
					...styles.ownershipTag,
					...{
						backgroundColor: '#000',
						display: 'flex',
						cursor: 'pointer',
						textTransform: 'capitalize',
						padding: '2px 5px',
					},
				}}>
				{action}
			</div>
		);
	};

	const renderCard = ({ status, last4 }, userId, cardType) => {
		if (!status) {
			status = 'not issued';
		}

		let avaliableActions = [];
		switch (status) {
			case 'not issued':
				avaliableActions = ['order card'];
				break;
			case 'active':
				avaliableActions = ['suspend', 'revoke', 'reorder'];
				break;
			case 'suspended':
				avaliableActions = ['activate', 'revoke', 'reorder'];
				break;
			case 'unactivated':
				avaliableActions = ['revoke', 'reorder'];
				break;
			case 'terminated':
				avaliableActions = ['reorder'];
				break;
			default:
				break;
		}
		return (
			<div style={styles.cardWrapper}>
				<div style={styles.cardStatusColumn}>
					{status !== 'not issued' && (
						<span
							style={{
								fontSize: 16,
								fontWeight: '400',
								color: '#000',
							}}>
							x{last4}
						</span>
					)}
					<span
						style={{
							fontSize: 12,
							fontWeight: '400',
							color: '#989898',
							textTransform: 'capitalize',
						}}>
						{status}
					</span>
				</div>
				<div style={styles.cardActionRow}>
					{avaliableActions.map(action => renderCardActionButton(action, userId, cardType))}
				</div>
			</div>
		);
	};

	const activateCard = async (password, comment) => {
		await API.post(`/banking-admin/business/activate-card/`, {
			userId: selectedUserId,
			businessProfileId,
			password,
			comment,
			cardType,
		});
		onRefresh();
	};

	const suspendCard = async (password, comment) => {
		await API.post(`/banking-admin/business/suspend-card/`, {
			userId: selectedUserId,
			businessProfileId,
			password,
			comment,
			cardType,
		});
		onRefresh();
	};

	const revokeCard = async (password, comment) => {
		await API.post(`/banking-admin/business/revoke-card/`, {
			userId: selectedUserId,
			businessProfileId,
			password,
			comment,
			cardType,
		});
		onRefresh();
	};

	const reorderCard = async (password, comment) => {
		await API.post(`/banking-admin/business/reorder-card/`, {
			userId: selectedUserId,
			businessProfileId,
			password,
			comment,
			cardType,
		});
		onRefresh();
	};

	const orderCard = async (password, comment) => {
		await API.post(`/banking-admin/business/order-card/`, {
			userId: selectedUserId,
			businessProfileId,
			password,
			comment,
			cardType,
		});
		onRefresh();
	};

	const tableColumns = [
		{
			Header: '',
			Cell: row => (
				<div>
					<div
						style={{
							width: 10,
							height: 10,
							background: row.original.isFlaggedForFraud ? '#E32727' : 'transparent',
							borderRadius: '50%',
						}}
					/>
				</div>
			),
			className: 'text-center d-flex align-items-center',
			maxWidth: 34,
			minWidth: 34,
		},
		{
			Header: 'Name',
			accessor: 'name',
			className: 'text-center d-flex align-items-center',
			maxWidth: 100,
		},
		{
			Header: 'Ownership',
			accessor: '',
			className: 'text-center d-flex align-items-center',
			Cell: row =>
				renderOwnership({
					title: row.original.title,
					isAdmin: row.original.admin,
					isOptedIn: row.original.optIn,
					ownershipPercentage: row.original.ownershipPercentage,
				}),
		},
		{
			Header: 'Physical Card',
			accessor: '',
			Cell: row =>
				renderCard(
					{
						status: row.original.physicalCheckingCard?.status,
						last4: row.original.physicalCheckingCard?.last4,
					},
					row.original.userId,
					'physical',
				),
		},
		{
			Header: 'Virtual Card',
			accessor: '',
			Cell: row =>
				renderCard(
					{
						status: row.original.virtualCheckingCard?.status,
						last4: row.original.virtualCheckingCard?.last4,
					},
					row.original.userId,
					'virtual',
				),
		},
	];

	const submitAction = async (password, comment) => {
		switch (action) {
			case 'revoke':
				revokeCard(password, comment);
				break;
			case 'reorder':
				reorderCard(password, comment);
				break;
			case 'order card':
				orderCard(password, comment);
				break;
			case 'activate':
				activateCard(password, comment);
				break;
			case 'suspend':
				suspendCard(password, comment);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<PasswordAndCommentModal
				prependContent={
					<Col md="12">
						{action === 'reorderCard' && (
							<Col md="12">
								<FormRadio
									inline
									name="virtual"
									checked={cardType === 'virtual'}
									onChange={() => {
										setCardType('virtual');
									}}>
									virtual
								</FormRadio>
								<FormRadio
									inline
									name="physical"
									checked={cardType === 'physical'}
									onChange={() => {
										setCardType('physical');
									}}>
									physical
								</FormRadio>
							</Col>
						)}
					</Col>
				}
				actionLabel={action}
				onConfirm={submitAction}
				toggle={open}
			/>

			<Row className="py-4 d-flex justify-content-between">
				<Col
					xs="12"
					sm="12"
					md="12"
					lg="12"
					xl="12"
					// className="col d-flex align-items-center justify-content-between"
				>
					<Card small className="mb-3">
						<CardHeader className="border-bottom">
							<h6 className="m-0">Owners</h6>
						</CardHeader>
						<CardBody className="p-0">
							<ReactTable
								getTdProps={() => ({
									style: { borderLeft: `none`, borderRight: `none` },
								})}
								// className="-highlight"
								columns={tableColumns}
								data={owners}
								resizable={false}
								usePagination={true}
								getTrProps={(state, rowInfo) => {
									if (rowInfo && rowInfo.row) {
										return {
											onClick: e => {
												if (e.target.className === 'card-action') {
													return;
												}
												history.push(
													`/profile/banking-admin/details/${rowInfo.original.userId}`,
												);
											},
											// style: {
											// 	cursor: 'pointer',
											// },
										};
									} else {
										return {};
									}
								}}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};

const styles = {
	cardContainer: {
		// minWidth: 558,
		height: 200,
		background: '#F1F1F1',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 20,
		flex: 1,
	},
	cardTitle: {
		height: 76,
		borderBottom: '1px solid #CCCCCC',
		fontWeight: '500',
		fontSize: 18,
		color: '#000',
		paddingLeft: 26,
		paddingRight: 26,
		alignItems: 'center',
		display: 'flex',
	},
	cardBody: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingLeft: 26,
		paddingRight: 26,
	},
	itemWrapper: {
		marginRight: 50,
		marginTop: 10,
	},
	itemLabel: {
		fontSize: 12,
		fontWeight: 'normal',
		color: '#000',
		textTransform: 'capitalize',
		whiteSpace: 'nowrap',
	},
	itemValue: {
		marginTop: 6,
		fontSize: 18,
		fontWeight: '500',
		color: '#000',
		textTransform: 'capitalize',
	},
	ownershipWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-bsetween',
	},
	ownershipRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginBottom: 5,
	},
	ownershipTitle: {
		fontSize: 16,
		fontWeight: '400',
		color: '#000',
		marginRight: 5,
	},
	ownershipTag: {
		display: 'block',
		padding: '1px 3px',
		borderRadius: 4,
		fontSize: 12,
		fontWeight: '400',
		color: '#FFF',
		marginRight: 3,
	},
	ownershipPercentage: {
		fontSize: 12,
		fontWeight: '400',
		color: '#989898',
		display: 'flex',
	},
	cardWrapper: {
		borderRadius: 10,
		background: '#F1F1F1',
		padding: 10,
		display: 'flex',
		flexDirection: 'row',
		height: 50,
		width: 300,
	},
	cardStatusColumn: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	cardActionRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		flex: 1,
	},
};
export default OwnersDisplay;
